import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  submit() {
    if (this.element instanceof HTMLFormElement) {
      this.element.requestSubmit();
      return;
    }

    this.element.form.requestSubmit();
  }
}
