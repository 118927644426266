import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener(
      "animationend",
      this.removeElement.bind(this)
    );
  }

  disconnect() {
    this.element.removeEventListener(
      "animationend",
      this.removeElement.bind(this)
    );
  }

  removeElement(event) {
    setTimeout(() => {
      this.element.parentElement.remove();
    }, 2000);
  }
}
